$(document).ready(function () {

    console.log('ready');

    $(".arrowdown").click(function() {
        $('html, body').animate({
            scrollTop: $("#main").offset().top - $('#header .navbar').outerHeight() + 10
        }, 1000);
    });

    $(window).on('scroll', function() {
        if(window.pageYOffset > 0) {
            $('.navbar').addClass('scrolled');
            $('.navbar').addClass('navbar-dark');
            $('.navbar').removeClass('navbar-light');
        } else {
            $('.navbar').removeClass('scrolled');
            $('.navbar').addClass('navbar-light');
            $('.navbar').removeClass('navbar-dark');
        }
    });
});
